.file-upload {
    border: 2px solid var(--bordercolor);
    width: 100%;
    max-width: 300px;
    border-radius: 15px;

    label {
        text-align: center;
        cursor: pointer;

        .document-upload-icon,
        .document-remove-icon {
            display: block;
            width: 50px;
            height: 50px;
            margin: 0 auto;
        }

        p {
            margin: 10px 0 0;
            font-size: 12px;
            word-break: break-all;
        }
    }

    .document-preview {
        width: 100%;
        height: 100%;
        background-size: cover;
        border-radius: 6px;
        background-position: center;
    }

    input {
        opacity: 0;
        position: absolute;
        pointer-events: none;
    }

    &--over {

        label {

            .document-upload-icon,
            .document-remove-icon {
                svg {
                    path {
                        stroke: var(--fontcolor);
                    }
                }
            }
        }
    }
}