.app {
    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 40px;
        max-width: var(--max-width);
        margin: 0 auto;
        padding: 20px;

        h1 {
            font-size: 16px;
        }

        @media (max-width: 670px) {
            flex-wrap: wrap;
            gap: 10px;

            h1 {
                flex: 1 1 100%;
                order: 2;
            }

            .branding {
                order: 1;
            }
        }
    }

    .branding {
        display: flex;
        justify-content: space-between;
        gap: 20px;

        &-logo {
            width: 80px;
            height: 40px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: 0 50%;

            &.aripaev {
                background-image: url('../../../public/aripaev-logo.png');
            }

            &.infopank {
                background-image: url('../../../public/infopank-logo.png');
                height: 44px;
                width: 97px;
            }
        }
    }
}