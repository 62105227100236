:root {
  --max-width: 800px;
  --border-radius: 12px;
  --input-border-radius: 8px;

  --primary: rgb(214, 30, 31);
  --primarylight: #434445;

  --primarydark: #191f22;
  --primarydarkalt: #454545;

  --backgroundcolor: rgb(245, 245, 245);

  --basecolor: #FFF;
  --basecoloralt: #e5ebf6;

  --boxcolor: #f4f4f6;

  --fontcolor: rgb(50, 50, 50);
  --fontcoloralt: #757575;

  --bordercolor: rgb(245, 245, 245);
  --bordercoloralt: #c9cace;

  --buttoncolor: #fff;
  --buttoncolormuted: #797a7e;

  --error: #d92028;
  --success: rgb(1, 201, 108);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  font-family: 'Inter', sans-serif;
}

body {
  color: var(--fontcolor);
  background-color: var(--backgroundcolor);
}

a {
  color: inherit;
  text-decoration: none;
}